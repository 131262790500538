.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container for the entire auth section */
.authcontainer {
  width: 100%;
  max-width: 450px;
  padding: 20px 40px 100px 40px;
  margin: 0 auto;
  background-color: #007bff;
  border-radius: 0px 0px 20px 20px;
}

/* Card styling for the login form */
.auth.card {
  padding: 30px;
  border: none;
  margin-top: -100px;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

/* Form elements spacing and styling */
.auth .form-control {
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

/* Buttons styling */
.auth .btn {
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
}

.auth .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.auth .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.auth .btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.auth .btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

/* Divider styling */
.auth p {
  margin: 0;
  padding: 10px 0;
  color: #6c757d;
  font-size: 14px;
}

/* Headings styling */
.authcontainer .fs-5 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.authcontainer .fs-6 {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 20px;
}
